import logo from './cloud.svg';
import './App.css';
import { Component } from "react";

function App() {
  return (
    <div className="App">
      <Cloud />
    </div>
  );
}

class Cloud extends Component {
	constructor(props) {
		super(props);
		this.eventSource = new EventSource("/events");
		this.state = {
			color: '0000FF',
			words: ''
		};
	}

	componentDidMount() {
		this.eventSource.addEventListener('color', e => {
			let json = JSON.parse(e.data);
			this.setState({
				color: json.color
			});
		});
		this.eventSource.addEventListener('words', e => {
			let json = JSON.parse(e.data);
			this.setState({
				words: json.words
			});
		});
	}

	render() {
		return (
      <header style={{ backgroundColor: `#${this.state.color}` }} className="App-header">
				<>
					<img src={logo} className="App-logo" alt="logo" />
					<h3>{this.state.words}</h3>
				</>
      </header>
		);
	}
}

export default App;
